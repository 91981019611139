.footer {
    background-color: #5e5e5e9b;
    padding: 20px 0;
    margin-top: 2vw;
    width: 100%;
    text-align: center;
    height: 25vh;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    max-width: 1400px;
    margin: 0 auto;
}


.footer-block {
    text-align: start;
    max-width: 300px;
}

.footer-block h5 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.footer-block p {
    margin: 5px 0;
    font-size: 14px;
    color: #d3dde1c3;
    font-weight: 600;

}

.footer-block a {
    display: block;
    text-align: left;
    color: #d3dde1c3;
    text-decoration: none;
    cursor: pointer;
}

.footer-block a:hover {
    text-decoration: underline;
}


@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;

    }

    .email__footer {
        font-size: 15px !important;
    }

    .contact-title3 {
        text-align: center;
    }

    .footer-block h5 {
        text-align: center;
    }

    .admin333 {
        text-align: center;
    }

}

.contact-title {
    display: flex;
    justify-content: flex-start;
}

.contacts {
    display: flex;
    flex-direction: column;
}

.contacts_inner {
    display: flex;
    gap: 20px;
}

.social {
    display: flex;
    gap: 10px;
}

.logo-image {
    height: 70px;
    width: 70px;
}

.copyright {
    display: flex;
    /* flex-direction: column;
    align-items: center; */
    margin-top: 20px;
    font-size: 14px;
    border-top: 1px solid #d3dde1c3;
}

.copyright p {
    display: block;
    text-align: left;
    color: #d3dde1c3;
    font-weight: 600;
}


.copyright__inner {
    margin: 10px auto 0;
    width: 1250px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red;     */
}

.line {
    width: 100%;
    border-top: 1px solid #464646;
    margin-bottom: 10px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.logo-container h4 {
    margin: 0;
    color: #fff !important;

}

@media (max-width: 768px) {
    .logo-container {
        justify-content: center;
    }
}
