@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&family=Comfortaa:wght@300..700&family=Comic+Neue:wght@400;700&family=Dancing+Script:wght@400..700&family=Dosis:wght@200..800&family=Josefin+Sans:wght@500&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Bubbles&family=Rubik+Doodle+Shadow&family=Rubik+Glitch&family=Sevillana&family=Single+Day&display=swap');

body {
  padding: 0;
  margin: 0;
  /* font-family: Dosis !important; */
  font-weight: 400;
}


.nav-link {
  font-weight: 400 !important;
}

.nav-link.active {
  color: blue !important;
}

.text-dark {
  display: flex;
  gap: 10px;
}

.logoText {
  font-weight: 500;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
}

.container-fluid {
  padding: 0 !important;
}

.table span {
  display: flex;
}

.table td {
  font-size: 13px;
}

.sidebar {
  min-height: 100vh;
}

.main-content {
  min-height: 100vh;
}

.app-container {
  display: flex;
}

.content {
  flex: 1;
}

.table-striped tbody tr:nth-child(even) {
  background-color: gray;
}

.table-striped tbody tr:nth-child(odd) {
  background-color: white;
}

.table th,
.table td {
  vertical-align: middle;
}

.table-responsive {
  overflow-x: auto;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .pagination-wrapper {
    flex-direction: column;
  }
}


.row10 {
  display: flex !important;

}

.verification-code-box {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  text-align: center;
  margin: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .verification-code-box {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
  }
}


.navbar {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-counter-container {
  display: flex;
  align-items: center;
}

.search-counter-button {
  display: flex;
}

.search-counter-button button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
  color: #333;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-counter-button button:hover,
.tablinks.active {
  color: #007bff;
}

.user {
  display: flex;
  align-items: center;
}

.user svg {
  margin-right: 5px;
}

.user p {
  font-size: 14px;
  margin: 0;
}


.custom-modal .modal-dialog {
  max-width: 80%;
}

@media (min-width: 1200px) {
  .custom-modal .modal-dialog {
    max-width: 60%;
  }
}

.pills-outline {
  display: flex;
  gap: 10px;
}

.tab-link {
  text-decoration: none;
}

.tab-button {
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-size: 14px;
}

.tab-button:hover {
  background-color: #f0f0f0;
}

.odd-row {
  background-color: #17148D;
}

.form-control::placeholder {
  font-size: 12px;
  color: #ccc
}


.navbar__sidebar-open {
  display: none;
}

@media (max-width: 599px) {
  .navbar__sidebar-open {
    display: block;

  }
}

.page-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content-wrap {
  flex: 1;
}




/* homePage.css */

.home-container {
  padding: 40px;
}

.home-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home-description {
  font-size: 18px;
  margin-bottom: 20px;
}

.home-card {
  border: none;
  box-shadow: none;
}

.map-container {
  position: relative;
  padding-bottom: 75%;
  /* Измените это значение по вашему усмотрению */
  height: 0;
  overflow: hidden;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.personal-area-data {
  display: flex;
  align-items: center;
  gap: 1vw;
  margin-top: 1.5vw;

}

.modalact .modal-dialog {
  max-width: 30%;
  margin: auto;
  margin-top: 2vw;
}

.modalact .modal-content {
  padding: 20px;
}



Button {
  font-size: 12px !important;
  /* color: #fff !important; */
}

@media (max-width: 990px) {
  .custom-dropdown-menu {
    z-index: 1050 !important;
    position: relative !important;
  }

  .navbar {
    z-index: 1000 !important;
  }

  .navbar-collapse {
    background-color: white;
    padding: 10px;
  }

  .text-dark span {
    font-size: 12px;
  }
}

.navbar_titile {
  color: white !important;
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: white !important;
}
.nav-link:focus{
  color: #0D6EFD  !important;
}


@media (max-width: 999px) {
  .navbar-nav .nav-link {
    color: black !important;
  }
  .navbar-nav .nav-link:hover {
    color: blue !important;
  }
}
