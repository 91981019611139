.reest{
    display: flex;
    justify-content: center;
    align-items:center;
    margin-bottom: 1vw;
}
.table5 th{
text-align: center;
}

.file-table > tr> td > a {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.78);
}
