.saveButton{
    margin: 0 auto 40px;
}


.action_save{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.action-center{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.modalact .modal-dialog {
    max-width: 30%;
    margin: auto;
    margin-top: 2vw;
}
.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}
.list-group-container {
    width: 35%;
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}

.navbar_container{
    height: 100%;
}

.btn-groups{
    display: flex;
    gap: 10px
}

.btn-groups > button {    
    border-radius: 5px;
}

.modalact .modal-content {
    padding: 20px;
}




    @media only screen and (max-width: 768px) {
        .list-group-container {
            max-width: 100%;
        }
        
    .navbar_container {
        display: flex;
        flex-wrap: wrap;
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
    }

    .list-group-container {
        width: 100%;
        overflow-y: auto;
        margin-bottom: 10px;
    }

    }
