.flex-container{
    display: flex;
    justify-content: flex-start;
    border: none !important;
}

.action_save{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-group-container {
    width: 50%;
    height: 100%;
    overflow-y: auto;
    padding: 0 5px;
    margin-bottom: 10px;
}


.list-group-container-group{
    width: 30%;
    max-width: 30%;
    min-width: 240px;
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}
