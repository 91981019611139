.home-container {
    padding-top: 60px;
    margin-bottom: 3vw;
}

.home-title {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 20px;
}

.map-card,
.description-card {
    border: 1px solid #f4f4f4;
    background-color: #ffffff;
    margin-top: 20px;
    height: 250px !important;
}

.map-image-container {
    overflow: hidden;
}

.map-image {
    width: 100%;
    height: auto;
}

.search-panel {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.search_button {
    height: 34px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.pagination-panel {
    display: flex;
    flex-direction: row;
    gap: 5px;
}


.main-table>tr>td {
    font-size: 12px;
}


@media (max-width: 992px) {

    .description-card h6 {
        font-size: 13px;
    }

    .cardtext-p {
        font-size: 12px;
    }

    .home-title {
        font-size: 20px;
    }
}

/* /// page cancele and ads/// */
.choiceZakup {
    max-width: 600px !important;
}

.moreTable {
    padding: 8px 3px !important;
}

.cancelStatus {
    color: red;
    width: 70px;
    border: 1px solid red;
    text-align: center;
    transform: rotate(-5deg);
    border-radius: 5px;
}

.searchInput {
    margin-top: 12px !important;
}

.cancel {
    color: red !important;
}

.good {
    color: green !important;
}

.arrowPagination {
    display: flex;
    align-items: center;
}

.hr {
    margin: 25px 0 0 0 !important;
}

.titleTh {
    font-weight: 600;
    line-height: 13px;
    padding: 15px 10px !important;
}

.cursor {
    cursor: pointer;
}

.home-container {
    padding: 20px;
}

.home-title {
    margin-bottom: 20px;
}

.map-card iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.description-card {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .map-card iframe {
        height: 300px;
    }
}